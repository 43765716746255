.login-form{
    padding-top: 100px;
}
.form-control{
    border-color: #1f252a !important;
}

.label-form-upload{
    border: dashed;
    display: inherit;
    padding: 20px;
    border-width: thin;
    border-radius: .3rem;
    cursor: pointer;
   
}
.label-form-upload-loading{
    border: solid;
    display: inherit;
    padding: 20px;
    border-width: thin;
    border-radius: .3rem;
    border-color: aliceblue;
    cursor: not-allowed;
   
}

