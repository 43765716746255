.sidebar-default {
    text-align: left;
    padding-top: 4em;
}

.sidebar-sticky {
    min-height: calc(100vh - 64px);
    padding-top: 1em;
    
    .nav-link {
        color: #fff;
        padding: 20px;

        &:hover {
            color: var(--orange-primary);
            transition: all 300ms ease-in-out;
        }
    }
}