.btn-primary {
    background-color: var(--orange-primary);
    border-color: var(--orange-primary);
    color: var(--black-primary);

    &:hover {
        background-color: #eb7e1e;
        border-color: #f3ae72;
        color: var(--black-primary);
    }

    &:not(:disabled):not(.disabled):active {
        background-color: var(--orange-primary);
        border-color: var(--orange-primary);
        color: var(--black-primary);
    }
}